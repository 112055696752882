<script setup lang="ts">
import useConfig from '../composables/useConfig.ts';
import { longtimeCookieSetting } from '../src/cookieSettings.ts';
import { useMainStore } from '../store/index.ts';

import { getUrlForLocale } from '~/src/domain.ts';

defineProps<{
    end?: boolean;
}>();

const config = useConfig();
const store = useMainStore();

const spellings: Record<string, Record<string, string>> = {
    zh: { traditional: '繁體', simplified: '简体' },
    tok: { latin: 'sitelen Lasina', sitelen: 'sitelen\xa0pona' },
    hbs: { latin: 'latinica', cyrillic: 'ћирилица' },
};

const relatedLocales: Record<string, Record<string, string>> = {
    no: { nb: 'Bokmål', nn: 'Nynorsk' },
};

// duplicate the same object for each related locale, so that the switch shows up in all of them
const relatedLocalesLookup: Record<string, Record<string, string>> = {};
for (const [parentLocale, localesRelatedToParent] of Object.entries(relatedLocales)) {
    relatedLocalesLookup[parentLocale] = localesRelatedToParent;
    for (const relatedLocale in localesRelatedToParent) {
        relatedLocalesLookup[relatedLocale] = localesRelatedToParent;
    }
}

const spellingCookie = useCookie('spelling', longtimeCookieSetting);
const getSpelling = () => {
    return store.spelling || (
        Object.hasOwn(spellings, config.locale)
            ? Object.keys(spellings[config.locale])[0]
            : null
    );
};
const setSpelling = (spelling: string) => {
    store.setSpelling(spelling);
    spellingCookie.value = store.spelling;
};
</script>

<template>
    <div class="pt-1">
        <div v-if="Object.hasOwn(spellings, config.locale)" class="btn-group m-2">
            <button
                v-for="(display, code) in spellings[config.locale]"
                :class="['btn btn-sm', getSpelling() === code ? 'btn-secondary disabled' : 'btn-outline-secondary', code === 'sitelen' ? 'sitelen' : '']"
                :disabled="getSpelling() === code"
                @click="setSpelling(code)"
            >
                {{ display }}
            </button>
        </div>
        <div v-if="Object.hasOwn(relatedLocalesLookup, config.locale)" class="btn-group m-2">
            <a
                v-for="(display, code) in relatedLocalesLookup[config.locale]"
                :href="getUrlForLocale(code)"
                :class="['btn btn-sm', config.locale === code ? 'btn-secondary disabled' : 'btn-outline-secondary']"
            >
                {{ display }}
            </a>
        </div>
        <Dropdown
            v-if="Object.keys($locales).length > 1"
            btn-class="btn-outline-secondary btn-sm"
            class="d-inline-block"
            :end="end"
            menu-class="locale-dropdown shadow"
        >
            <template #toggle>
                <Icon v="language" />
                {{ $locales[config.locale].name }}
                <small v-if="$locales[config.locale].extra" class="text-muted">
                    ({{ $locales[config.locale].extra }})
                </small>
            </template>

            <template #menu>
                <template v-for="(options, locale) in $locales">
                    <li v-if="locale !== config.locale" :key="locale" class="w-50">
                        <a :href="getUrlForLocale(locale)" class="dropdown-item">
                            <LocaleIcon :locale="options" />
                            {{ options.name }}
                            <small v-if="options.extra" class="text-muted">({{ options.extra }})</small>
                        </a>
                    </li>
                </template>
                <li class="dropdown-divider"></li>
                <li class="w-50">
                    <a :href="getUrlForLocale('_')" class="dropdown-item small">
                        <Logo class="mb-1" />
                        pronouns.page
                    </a>
                </li>
                <li class="w-50">
                    <LocaleLink locale="en" link="/new-version" class="dropdown-item small">
                        <Icon v="plus" />
                        <T>localise.shorter</T>
                    </LocaleLink>
                </li>
            </template>
        </Dropdown>
    </div>
</template>
