<script setup lang="ts">
defineProps<{
    hash: string | null;
}>();

const base = 'https://gitlab.com/PronounsPage/PronounsPage/-/commit';
</script>

<template>
    <a v-if="hash" :href="`${base}/${hash}`" target="_blank" rel="noopener">{{ hash.substring(0, 8) }}</a>
    <span v-else>–</span>
</template>
