<script setup lang="ts">
withDefaults(defineProps<{
    btnClass?: string;
    menuClass?: string;
    end?: boolean;
}>(), {
    btnClass: 'btn-secondary',
});

const shown = ref(false);

onMounted(() => {
    document.addEventListener('click', documentClicked);
});
onUnmounted(() => {
    document.removeEventListener('click', documentClicked);
});

const documentClicked = () => {
    shown.value = false;
};
</script>

<template>
    <div class="dropdown">
        <button
            :class="['btn dropdown-toggle', btnClass, shown ? 'show active' : '']"
            @click.stop="shown = !shown"
        >
            <slot name="toggle">
                Dropdown
            </slot>
        </button>
        <ul :class="['dropdown-menu', end ? 'dropdown-menu-end' : '', shown ? 'show' : '', menuClass]">
            <slot name="menu">
                <li class="dropdown-item">
                    Option
                </li>
            </slot>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
    .dropdown-menu-end {
        right: 0;
    }
</style>
